import(/* webpackMode: "eager" */ "/react-app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/react-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/react-app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/react-app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/react-app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/react-app/src/assets/logo.svg");
